import React from 'react';
import { IntercomProvider } from 'react-use-intercom';

export const wrapRootElement = ({ element }) => {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gh_hide_banner', false);
    }

    return (
        <IntercomProvider autoBoot appId={process.env.GATSBY_INTERCOM_ID}>
            {element}
        </IntercomProvider>
    );
};
